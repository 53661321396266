import React from 'react';
import { MainSectionTemplate } from 'components/main-section-template';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { Container } from 'components/container';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const SHeaderThird = styled(HeaderThird)`
    font-size: 1.75rem;
    line-height: 2.375rem;
    margin-bottom: 2rem;
    text-align: center;
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 2rem;
    text-align: center;
`;

export const CodeConsultingSection = () => {
    const { formatMessage } = useIntl();


    return (
        <>
            <MainSectionTemplate
                title="javascript.javaScriptCodeConsulting"
                subTitle="javascript.theExperienceAndSkills"
                firstParagraph="javascript.javaScriptIsAMultiParadigm"
                googleAnalytics={gaTrackedEvents.JAVASCRIPT.CTA.ESTIMATE_PROJECT}
            />
            <Container>
                <SHeaderThird>
                    {formatMessage({
                        id: 'javascript.submainSection.firstSubtitle',
                    })}
                </SHeaderThird>
                <SParagraph>
                    {formatMessage({
                        id: 'javascript.submainSection.firstParagraph',
                    })}
                </SParagraph>
                <SHeaderThird>
                    {formatMessage({
                        id: 'javascript.submainSection.secondSubtitle',
                    })}
                </SHeaderThird>
                <SParagraph>
                    {formatMessage({
                        id: 'javascript.submainSection.secondParagraph',
                    })}
                </SParagraph>
                <SHeaderThird>
                    {formatMessage({
                        id: 'javascript.submainSection.thirdSubtitle',
                    })}
                </SHeaderThird>
                <SParagraph>
                    {formatMessage({
                        id: 'javascript.submainSection.thirdParagraph',
                    })}
                </SParagraph>
                <SHeaderThird>
                    {formatMessage({
                        id: 'javascript.submainSection.fourthSubtitle',
                    })}
                </SHeaderThird>
                <SParagraph>
                    {formatMessage({
                        id: 'javascript.submainSection.fourthParagraph',
                    })}
                </SParagraph>
            </Container>
        </>
    );
};
